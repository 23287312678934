.modal-background {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10;
}

.dialog {
	color: white;
	background: #22151c;
	/* #160d12;*/
	border-top: 1px solid #2a0d17;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 2em);
	max-width: 31em;
	min-height: 20em;
	border-radius: 0.5em;
}

.dialog > .dialog-item {
	padding: 1.5em;
}

.dialog > .header {
	font-weight: bold;
	color: #ff0068;
	display: flex;
	align-items: center;
	height: 2em;
}

.dialog > .header > .icon {
	height: 1.5em;
	margin-right: 0.5em;
}

.dialog > .header > span {
	flex: 1;
}

.dialog > .header > .cancel-button {
	background: none;
	border: none;
	color: #ff0068;
	font-size: 2em;
	margin: 0;
	padding: 0;
	opacity: 0.25;
	height: 1em;
	line-height: 0;
	cursor: pointer;
}

.dialog > .header > .cancel-button:hover {
	opacity: 0.5;
}

.watch-list {
	color: white;
	background: rgba(0, 0, 0, 0.6);
	height: 1em;
	align-self: start;
	padding: 1em;
	line-height: 0;
	border-radius: 1em;
	font-weight: bold;
	position: relative;
	opacity: 0.8;
	cursor: default;
}

.watch-list:hover {
	opacity: 1;
}

.watch-list > ul {
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	left: 50%;
	top: 3.5em;
	transform: translate(-50%, -10%);
	line-height: 1em;
	list-style-type: none;
	padding: 1em;
	margin: 0;
	border-radius: 0.5em;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.watch-list:hover > ul {
	opacity: 1;
	transform: translate(-50%, 0);
}

.watch-list > ul::after {
	content: ' ';
	width: 0;
	height: 0;
	border-left: 1em solid transparent;
	border-right: 1em solid transparent;
	border-bottom: 1em solid rgba(0, 0, 0, 0.6);
	position: absolute;
	left: 50%;
	top: -1em;
	transform: translate(-50%, 0);
}

.watch-list > ul > li {
	white-space: nowrap;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.watch-list > ul > li:first-child {
	margin-top: 0;
}

.watch-list > ul > li:last-child {
	margin-bottom: 0;
}

.watch-list > ul > li > .username {
	margin-left: 0.25em;
}

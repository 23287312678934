html,
body {
	padding: 0;
	margin: 0;
	overscroll-behavior-y: none;
	background: black;
}

html,
body,
input,
textarea,
button {
	font-family: 'Outfit';
}

input[type='text'] {
	color: white;
	padding: 1em;
	border-radius: 0.25em;
	background: #1a1a1a;
	border: none;
	box-shadow: 0 1px 0px rgb(255 255 255 / 20%);
	resize: none;
}

* {
	box-sizing: border-box;
}

main {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
}

main > header {
	width: 100%;
	height: 2.5em;
	background: #120f11;
	box-shadow: 0 1px 1px #00000045;
	position: relative;
	padding: 1.4em;
	display: flex;
	align-items: center;
	color: #ff0068;
	font-weight: bold;
}

main > header > .icon {
	height: 1.5em;
	margin-right: 0.5em;
}

main > header > span {
	flex: 1;
}

main > .container {
	display: flex;
	flex-direction: row;
	flex: 1;
	height: calc(100% - 3em);
}

main > .container > .live-chat {
	width: 25em;
	border-left: 1px solid rgb(58 41 50);
	min-height: 0;
}

main > .container > .video {
	flex: 1;
}

.watch-list {
	z-index: 9999;
}

@media (max-width: 800px) {
	main > .container {
		flex-direction: column;
	}

	main > .container > .video {
		flex-grow: initial;
	}

	main > .container > .live-chat {
		width: 100%;
		flex: 1;
	}

	main > .container > .video > .video-container {
		margin: 0;
		border-radius: 0;
		width: 100%;
	}

	main > .container > .video > .video-container > .video-placeholder,
	main > .container > .video > .video-container > video {
		border-radius: 0;
	}

	main > .container > .video > .about > .title > .now-streaming {
		display: none;
	}
}

.dialog-name p {
	margin: 0;
	text-align: center;
}

.dialog-name .name {
	font-size: 1.5em;
	text-align: center;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5em;
	margin-top: 1em;
	margin-bottom: 1em;
	background: rgba(0, 0, 0, 0.1);
	border-bottom: 2px solid #1a0f15;
	border-radius: 0.5em;
	padding: 1em;
	border: 1px solid rgba(0, 0, 0, 0.4);
}

.dialog-name .name > span {
	font-size: 0.6em;
	opacity: 0.5;
	font-weight: normal;
}

.dialog-name .name > input {
	font-size: 1em;
	padding: 0.3em 0.5em;
	font-weight: bold;
	width: 100%;
	text-align: center;
	color: white;
}

.dialog-name .buttons {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 0.5em;
}

.dialog-name .buttons > .button > * {
	font-size: 1.2em;
}

.dialog-name .buttons > .button > .link-button {
	font-size: 0.8em;
}

.video {
	background: #2c1923;
	color: white;
	display: flex;
	flex-direction: column;
	background-color: #2c1923;
	opacity: 1;
	position: relative;
	background-image: linear-gradient(
			30deg,
			#331f2a 12%,
			transparent 12.5%,
			transparent 87%,
			#331f2a 87.5%,
			#331f2a
		),
		linear-gradient(
			150deg,
			#331f2a 12%,
			transparent 12.5%,
			transparent 87%,
			#331f2a 87.5%,
			#331f2a
		),
		linear-gradient(
			30deg,
			#331f2a 12%,
			transparent 12.5%,
			transparent 87%,
			#331f2a 87.5%,
			#331f2a
		),
		linear-gradient(
			150deg,
			#331f2a 12%,
			transparent 12.5%,
			transparent 87%,
			#331f2a 87.5%,
			#331f2a
		),
		linear-gradient(
			60deg,
			#331f2a77 25%,
			transparent 25.5%,
			transparent 75%,
			#331f2a77 75%,
			#331f2a77
		),
		linear-gradient(
			60deg,
			#331f2a77 25%,
			transparent 25.5%,
			transparent 75%,
			#331f2a77 75%,
			#331f2a77
		),
		linear-gradient(45deg, #1e0f17, transparent);
	background-size: 80px 140px;
	background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

.video:before {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: red;
}

.video > .about {
	background: linear-gradient(45deg, #1e0f17, transparent);
	padding: 1em 1.4em;
	color: #f3e5ed;
	display: flex;
}

.video > .about > .title {
	flex: 1;
}

.video > .about > .title > .now-streaming {
	text-transform: capitalize;
	font-size: 0.8em;
	opacity: 0.5;
}

.video > .about > .title > h1 {
	margin: 0;
	padding: 0;
}

.video > .video-container {
	width: calc(100% - 2em);
	border-radius: 0.5em;
	position: relative;
	margin: 1em;
}

.video > .video-container > .video-placeholder {
	position: absolute;
	left: 0;
	top: 0;
	background: #101010;
	color: #a58a98;
	font-weight: bold;
	width: 100%;
	height: 100%;
	border-radius: 0.5em;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	/* background-image: url(${SkullIcon2.src}); */
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 75% 75%;
	text-align: center;
	padding: 1em;
}

.video > .video-container > video {
	z-index: 1;
	position: relative;
	width: 100%;
	border-radius: 0.5em;
	box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
}

.video > .video-container > video.is-live {
	background: #101010;
}

.video > .video-container > .listen-blocker {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.75);
	z-index: 1;
	border-radius: 0.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}

.video > .video-container > .listen-blocker:hover {
	background: rgba(0, 0, 0, 0.65);
}

.video > .video-container > .listen-blocker:hover > span {
	transform: scale(1.1);
}

.video > .video-container > .live {
	z-index: 2;
	right: 1em;
	bottom: 1em;
	background: #df023d;
	position: absolute;
	font-weight: bold;
	padding: 0.25em 0.5em;
	padding-top: 0.15em;
	border-radius: 0.5em;
	vertical-align: middle;
	padding-left: 1.5em;
	color: #ffd9e2;
	font-size: 0.85em;
}

.video > .video-container > .live::before {
	content: '';
	font-size: 2em;
	margin-right: 0.25em;
	display: block;
	background: #ffd9e2;
	width: 0.25em;
	height: 0.25em;
	border-radius: 0.25em;
	position: absolute;
	left: 0.3em;
	top: 50%;
	transform: translate(0, -50%);
}

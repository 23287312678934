.live-chat {
	display: flex;
	flex-direction: column;
	background: #1a1a1a;
	color: white;
}

.live-chat > .chat-history {
	flex: 1;
	list-style-type: none;
	padding: 0;
	margin: 0;
	padding: 1em;
	overflow: scroll;
}

.live-chat > .chat-history a,
.live-chat > .chat-history a:visited {
	color: #ffb3d2;
}

.live-chat > .chat-history a:hover {
	color: white;
}

.live-chat > .chat-history > li {
	margin-bottom: 1em;
	font-size: 0.85em;
}

.live-chat > .chat-history > li.placeholder {
	opacity: 0.5;
}

.live-chat > .message-form {
	display: flex;
	padding: 1em;
	gap: 0.5em;
	background: #141313;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.live-chat > .message-form > .new-message {
	flex: 1;
	height: 3.5em;
}

.live-chat > .message-form > .new-message:focus {
	background: #331f29;
}

.live-chat > .message-form > .button {
	height: 100%;
}

.live-chat > .message-form > .button > * {
	padding-left: 1.5em;
	padding-right: 1.5em;
}

@media (max-width: 800px) {
	.live-chat > .message-form {
		flex-direction: column;
	}

	.live-chat > .message-form > .button > * {
		width: 100%;
		height: 3em;
	}
}

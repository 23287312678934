.button {
	display: flex;
}

.button > input[type='submit'],
.button > button {
	border: none;
	background: linear-gradient(0deg, #7a1c35, #df0c40);
	color: white;
	font-weight: bold;
	padding: 0.5em 1em;
	letter-spacing: 0.05em;
	border-radius: 0.25em;
	cursor: pointer;
}

.button > input[type='submit']:hover,
.button > button:hover {
	filter: saturate(1.2);
}

.button.is-secondary > input[type='submit'],
.button.is-secondary > button {
	background: none;
	border: 1px solid #df0c40;
	color: #df0c40;
}

.button.is-link > input[type='submit'],
.button.is-link > button {
	background: none;
	border: none;
	color: #df0c40;
	text-decoration: underline;
}

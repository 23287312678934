.dialog-color .content > p {
	text-align: center;
	margin-bottom: 1em;
}

.dialog-color .colors {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 5em;
	max-width: 22em;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1.5em;
	background: rgba(0, 0, 0, 0.1);
	border-bottom: 2px solid #1a0f15;
	border-radius: 0.5em;
	padding-bottom: 2em;
	padding-top: 1em;
	padding-left: 2em;
	padding-right: 2em;
}

.dialog-color input[type='radio'] {
	appearance: none;
	background-color: var(--color);
	margin: 0;
	width: 3em;
	height: 3em;
	border-radius: 1.5em;
	display: flex;
	justify-self: center;
	align-self: center;
	cursor: pointer;
	border: 2px solid transparent;
}

.dialog-color input[type='radio']:hover,
.dialog-color input[type='radio']:focus {
	transform: scale(1.1);
}

.dialog-color input[type='radio']:checked {
	border: 2px solid black;
	outline: 2px solid white;
	transform: scale(1.1);
}

.dialog-color .buttons > .button {
	justify-content: center;
}

.dialog-color .buttons > .button > * {
	font-size: 1.2em;
}

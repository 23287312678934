.username-settings > button {
	font-weight: bold;
	background: none;
	border: 1px solid var(--color);
	color: var(--color);
	border-radius: 0.25em;
	cursor: pointer;
}

.username-settings:hover > button {
	background: var(--color);
	color: white;
}
